import { ParagraphSmall } from 'components/common/typography';
import { colors } from 'css/variables';
import createApiUrl from 'lib/create-api-url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Store } from 'redux/store';
import css from './auth-links.module.css';
import cx from 'classnames';

const AuthLinks: React.FC = () => {
  const { pathname } = useRouter();
  const { status, data } = useSelector(
    (state: Store) => state.auth,
    shallowEqual,
  );
  const currentListIndex = useSelector(
    (state: Store) => state.citationList.currentListIndex,
  );

  if (currentListIndex === -1) {
    return null;
  }

  if (status === 'to be determined') {
    return null;
  }

  if (status === 'not present') {
    return (
      <>
        <Link href="/login" prefetch={false}>
          <a className={cx(css.link, css.authLink)}>
            <ParagraphSmall color={colors.purple.light}>Log in</ParagraphSmall>
          </a>
        </Link>
        <Link href="/signup" prefetch={false}>
          <a className={cx(css.authButton, css.link, css.authLink)}>
            <ParagraphSmall color={colors.purple.light}>Sign up</ParagraphSmall>
          </a>
        </Link>
        <div
          id="g_id_onload"
          data-client_id={process.env.GOOGLE_CLIENT_ID}
          data-context="signin"
          data-cancel_on_tap_outside="false"
          data-login_uri={createApiUrl(
            `/auth/google/one-tap/callback?flow=signup&next=${encodeURIComponent(
              pathname,
            )}`,
          )}
        ></div>
      </>
    );
  }

  return (
    <Link href="/profile">
      <a className={cx(css.link, css.emailLink)}>
        <ParagraphSmall color={colors.purple.light}>
          {data?.email}
        </ParagraphSmall>
      </a>
    </Link>
  );
};

export default AuthLinks;
