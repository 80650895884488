import * as React from 'react';
import { rem } from 'lib/rem';
import { text, colors, defaults } from 'css/variables';
import Header from './header';
import { mod } from 'lib/mod';
import { Store, useAppDispatch } from 'redux/store';
import { abTestSlice } from 'redux/modules/ab-test-module';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import yn from 'yn';
import { configureScope } from 'lib/sentry';
import Tracking, { EventProperties } from 'lib/tracking';
import { useRouter } from 'next/router';

interface PageProps {
  children: React.ReactNode;
  header?: boolean;
  pageName: string;
  pageViewEventProps?: EventProperties;
}

const Page: React.FC<PageProps> = (props: PageProps) => {
  const { children, header = true } = props;
  const email = useSelector((state: Store) => state.auth.data?.email);
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    if (!yn(process.env.CHROME) && !yn(process.env.GDOCS)) {
      dispatch(abTestSlice.actions.initializeExperimentVariants('')); // For now empty string otherwise ip
    }
  }, [dispatch]);

  useEffect(() => {
    if (email) {
      configureScope((scope: any) => scope.setUser({ email: email }));
    } else {
      configureScope((scope: any) => scope.setUser(null));
    }
  }, [email]);

  useEffect(() => {
    if (props.pageViewEventProps) {
      Tracking.trackPage(props.pageName, props.pageViewEventProps);
    } else {
      Tracking.trackPage(props.pageName);
    }
  }, [props.pageName, props.pageViewEventProps, router.asPath]);

  return (
    <div>
      {header && <Header />}
      <main>{children}</main>
    </div>
  );
};

export default Page;
