import React, { FormEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import { colors } from 'css/variables';
import { useRef } from 'react';
import useClickOutsideDetector from 'lib/hooks/useClickOutsideDetector';
import { useDispatch, useSelector } from 'react-redux';
import { updateCitationListName } from 'redux/modules/citation-list-module';
import Button from 'components/common/button';
import { ListMetadata } from 'types/citation-list';
import PlusSVG from 'static/svgs/icon/12/plus.svg';
import { ParagraphMedium, ParagraphSmall } from 'components/common/typography';
import { Store } from 'redux/store';
import css from './citation-list.module.css';

const DELAY_FOR_NAME_UPDATE = 500;

const DownArrow = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L4 4L7 1"
        stroke={colors.purple.light}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const SharedListIcon = () => {
  return (
    <svg
      width="23"
      height="14"
      viewBox="0 0 23 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 12.3333C0.5 10.7685 1.76853 9.5 3.33333 9.5H10.6667C12.2315 9.5 13.5 10.7685 13.5 12.3333C13.5 12.9777 12.9777 13.5 12.3333 13.5H1.66667C1.02233 13.5 0.5 12.9777 0.5 12.3333Z"
        fill="#8277A2"
        stroke="#8277A2"
      />
      <circle cx="7" cy="4" r="3.5" fill="#8277A2" stroke="#8277A2" />
      <circle cx="16.5" cy="5.5" r="3" fill="#8277A2" stroke="#8277A2" />
      <path
        d="M21.6667 13.5H15.278C15.4212 13.1388 15.5 12.7451 15.5 12.3333C15.5 11.6849 15.3721 11.0657 15.14 10.5H20.3333C21.5299 10.5 22.5 11.4701 22.5 12.6667C22.5 13.1269 22.1269 13.5 21.6667 13.5Z"
        fill="#8277A2"
        stroke="#8277A2"
      />
    </svg>
  );
};

interface CitationListProps {
  lists: ListMetadata[];
  citationList: ListMetadata;
  showRename: boolean;
  lastSelectedListIndex: number;
  selectCitationList: (id: string) => void;
  closeRename: () => void;
  createNewCitationList: () => void;
}

const CitationList: React.FC<CitationListProps> = ({
  citationList,
  lists,
  showRename,
  selectCitationList,
  closeRename,
  createNewCitationList,
  lastSelectedListIndex,
}) => {
  const dispatch = useDispatch();
  const [showLists, setShowLists] = useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const email = useSelector((store: Store) => store.auth.data?.email);

  useEffect(() => {
    if (!showRename || !nameInputRef.current) return;
    nameInputRef.current.focus();
    nameInputRef.current.select();
  }, [showRename]);

  const handleSelectList = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
  ) => {
    e.stopPropagation(); // needed b/c root el has on click handler we don't want to trigger
    selectCitationList(id);
    setShowLists(false);
  };

  const handleSubmitName = async (e?: FormEvent): Promise<void> => {
    if (e) e.preventDefault();
    if (!showRename) return;
    setTimeout(() => {
      if (nameInputRef.current) {
        nameInputRef.current.value = '';
      }
      closeRename();
    }, DELAY_FOR_NAME_UPDATE);

    const newName = nameInputRef.current?.value;
    if (newName) {
      dispatch(
        updateCitationListName({
          id: citationList.id,
          name: newName,
        }),
      );
    }
  };

  useClickOutsideDetector(nameInputRef, handleSubmitName);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutsideDetector(wrapperRef, () => setShowLists(false));

  const handleShowLists = () => {
    if (showRename) return;
    setShowLists(!showLists);
  };

  const sharedWithMe = lists.filter(
    (l) => typeof email === 'string' && l.collaborators.includes(email),
  );
  const owned = lists.filter((l) => !sharedWithMe.includes(l));
  const lastUsedId = lists[lastSelectedListIndex].id;
  const currentListSharedWithMe =
    typeof email === 'string' && citationList.collaborators.includes(email);
  return (
    <div
      ref={wrapperRef}
      className={cx(css.wrapper, showLists && css.selected)}
      tabIndex={0}
      role="button"
      onClick={handleShowLists}
      onKeyPress={handleShowLists}
    >
      <div className={css.citationListNameWrapper}>
        <span className={cx(css.nameFixed, showRename && css.editing)}>
          {currentListSharedWithMe && (
            <span className={css.sharedListSvgWrapper}>
              <SharedListIcon />{' '}
            </span>
          )}
          <span className={css.citationListName}>{citationList.name}</span>
        </span>
        {showRename && (
          <form onSubmit={handleSubmitName} className={css.nameInputWrapper}>
            <input
              ref={nameInputRef}
              defaultValue={citationList.name}
              className={css.nameInput}
              type="text"
            />
            <Button onClick={handleSubmitName} size="small">
              Rename
            </Button>
          </form>
        )}
      </div>
      {!showRename && (
        <button className={css.showListsIcon}>
          <DownArrow />
        </button>
      )}

      <div
        className={cx(
          css.dropdownListContainer,
          showLists && css.dropdownListContainerActive,
        )}
      >
        <ul className={css.dropdownList}>
          {owned.map((list, i) => (
            <li key={list.id}>
              <button
                onClick={(e) => handleSelectList(e, list.id)}
                title={list.name} // tooltip text
                className={cx(
                  css.dropdownListItem,
                  lastUsedId === list.id && css.dropdownListItemCurrent,
                )}
              >
                {list.name.length > 40
                  ? `${list.name.substring(0, 33)}...`
                  : list.name}
                <span>
                  {lastUsedId === list.id ? (
                    <ParagraphSmall color={colors.purple.medium}>
                      current
                    </ParagraphSmall>
                  ) : (
                    <ParagraphSmall color="rgb(202, 157, 0)">
                      {list.length}{' '}
                      {list.length === 1 ? 'citation' : 'citations'}
                    </ParagraphSmall>
                  )}
                </span>
              </button>
              {i < owned.length - 1 && (
                <hr className={css.dropdownListItemLine} />
              )}
            </li>
          ))}
          <li className={cx(css.dropdownListItem, css.newCitationList)}>
            <button
              className={css.newCitationListBtn}
              onClick={createNewCitationList}
            >
              <span className={css.newCitationListIcon}>
                <PlusSVG />
              </span>
              <span className={css.newCitationListText}>Create new list</span>
            </button>
          </li>
          {sharedWithMe.length > 0 && (
            <li key="separator" className={css.sharedText}>
              <ParagraphMedium style={{ margin: '15px 0px' }} fontWeight="bold">
                Shared with you
              </ParagraphMedium>
            </li>
          )}
          {sharedWithMe.map((list, i) => (
            <li key={list.id}>
              <button
                onClick={(e) => handleSelectList(e, list.id)}
                title={list.name} // tooltip text
                className={cx(
                  css.dropdownListItem,
                  lastUsedId === list.id && css.dropdownListItemCurrent,
                )}
              >
                {list.name.length > 40
                  ? `${list.name.substring(0, 33)}...`
                  : list.name}

                <span>
                  {lastUsedId === list.id ? (
                    <ParagraphSmall color={colors.purple.medium}>
                      current
                    </ParagraphSmall>
                  ) : (
                    <ParagraphSmall>
                      {list.length}{' '}
                      {list.length === 1 ? 'citation' : 'citations'}
                    </ParagraphSmall>
                  )}
                </span>
              </button>
              {i < sharedWithMe.length - 1 && (
                <hr className={css.dropdownListItemLine} />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CitationList;
