import * as React from 'react';
import { colors, text } from 'css/variables';
import css from './typography.module.css';

interface TypographyProps {
  children: React.ReactNode;
  fontWeight?: string | number;
  style?: React.CSSProperties;
  color?: string;
}

export const ParagraphMedium: React.FC<TypographyProps> = ({
  style,
  fontWeight,
  color,
  children,
}: TypographyProps): JSX.Element => {
  return (
    <span
      style={{
        ...style,
        fontWeight: fontWeight || text.paragraph.weight,
        color: color || colors.grey.darkest,
      }}
      className={css.paragraphMedium}
    >
      {children}
    </span>
  );
};

export const ParagraphSmall: React.FC<TypographyProps> = ({
  color,
  children,
}: TypographyProps): JSX.Element => {
  return (
    <span
      style={{ color: color || colors.grey.darkest }}
      className={css.paragraphSmall}
    >
      {children}
    </span>
  );
};

export const LabelMedium: React.FC<TypographyProps> = ({
  style,
  color,
  children,
}: TypographyProps): JSX.Element => {
  return (
    <span
      style={{ ...style, color: color || colors.grey.darkest }}
      className={css.labelMedium}
    >
      {children}
    </span>
  );
};

export const LabelSmall: React.FC<TypographyProps> = ({
  color,
  children,
}: TypographyProps): JSX.Element => {
  return (
    <>
      <span
        style={{ color: color || colors.grey.darkest }}
        className={css.labelSmall}
      >
        {children}
      </span>
    </>
  );
};
