import * as React from 'react';
import css from './toggle.module.css';
import cx from 'classnames';

interface ToggleProps {
  onChange: (toggle: 'activated' | 'deactivated') => void;
  toggle: 'activated' | 'deactivated';
}

const Toggle = (props: ToggleProps): JSX.Element => {
  return (
    <div>
      <label className={css.toggleSwitch}>
        <input
          type="checkbox"
          onChange={(event) => {
            props.onChange(event.target.checked ? 'activated' : 'deactivated');
          }}
          checked={props.toggle === 'activated'}
        />
        <span className={cx(css.slider, css.round)} />
      </label>
    </div>
  );
};

export default Toggle;
