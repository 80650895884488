import React from 'react';
import { colors } from 'css/variables';
import Link from 'next/link';
import LogoSVG from 'static/svgs/logo.svg';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import FacebookSVG from 'static/svgs/facebook.svg';
import TwitterSVG from 'static/svgs/twitter.svg';
import InstagramSVG from 'static/svgs/instagram.svg';
import { ParagraphSmall } from 'components/common/typography';
import AuthLinks from 'components/app/auth-links';
import CitationLists from 'components/app/citation-lists';
import Button from './button';
import css from './header.module.css';
import cx from 'classnames';

export const ChromeSVG = (props: any): any => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 305 305"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#fff"
      d="M95.506 152.511c0 31.426 25.567 56.991 56.994 56.991 31.425 0 56.99-25.566 56.99-56.991 0-31.426-25.565-56.993-56.99-56.993-31.427 0-56.994 25.567-56.994 56.993z"
    />
    <path
      fill="#fff"
      d="M283.733 77.281a2.5 2.5 0 0 0-.023-2.513c-13.275-22.358-32.167-41.086-54.633-54.159C205.922 7.134 179.441.012 152.5.012c-46.625 0-90.077 20.924-119.215 57.407a2.497 2.497 0 0 0-.212 2.81l42.93 74.355a2.5 2.5 0 0 0 4.586-.625c8.465-32.799 38.036-55.706 71.911-55.706 2.102 0 4.273.096 6.455.282.071.007.143.01.214.01H281.56c.899 0 1.729-.482 2.173-1.264z"
    />
    <path
      fill="#fff"
      d="M175.035 224.936a2.496 2.496 0 0 0-2.646-.876 74.14 74.14 0 0 1-19.889 2.709c-28.641 0-55.038-16.798-67.251-42.794a2.797 2.797 0 0 0-.098-.188L23.911 77.719a2.496 2.496 0 0 0-2.165-1.25h-.013a2.5 2.5 0 0 0-2.165 1.272C6.767 100.456 0 126.311 0 152.511c0 36.755 13.26 72.258 37.337 99.969 23.838 27.435 56.656 45.49 92.411 50.84a2.502 2.502 0 0 0 2.535-1.223l42.941-74.378c.508-.88.433-1.98-.189-2.783z"
    />
    <path
      fill="#fff"
      d="M292.175 95.226h-85.974a2.499 2.499 0 0 0-1.75 4.285c14.385 14.102 22.307 32.924 22.307 53 0 15.198-4.586 29.824-13.263 42.298a2.53 2.53 0 0 0-.112.178l-61.346 106.252a2.5 2.5 0 0 0 2.165 3.75h.028c40.37-.45 78.253-16.511 106.669-45.222C289.338 231.032 305 192.941 305 152.511c0-19.217-3.532-37.956-10.498-55.698a2.5 2.5 0 0 0-2.327-1.587z"
    />
  </svg>
);

const Header: React.FC = () => {
  return (
    <header className={css.header}>
      <div className={css.container}>
        <Link href="/">
          <a className={cx(css.headerLink, css.link)}>
            <div className={cx(css.headerName, 'label-medium')}>
              <div className={css.headerLogo}>
                <LogoSVG />
              </div>
              Bibcitation
            </div>
          </a>
        </Link>
        <CitationLists />
        <nav className={css.headerNav}>
          <Link href="/contact" prefetch={false}>
            <a className={cx(css.headerLink, css.contactLink, css.link)}>
              <ParagraphSmall color={colors.white}>Contact</ParagraphSmall>
            </a>
          </Link>
          <span className={css.headerIconButton}>
            <FacebookShareButton
              url="https://www.bibcitation.com"
              style={{ marginRight: 24, marginTop: 4 }}
              aria-label="Visit our Facebook"
            >
              <FacebookSVG />
            </FacebookShareButton>
          </span>
          <a
            href="https://www.instagram.com/bibcitation"
            rel="nofollow noreferrer"
            target="_blank"
            className={cx(css.headerIconButton, css.link)}
            aria-label="Visit our Instagram"
            style={{ marginRight: 24, marginTop: 4 }}
          >
            <InstagramSVG />
          </a>
          <span className={css.headerIconButton}>
            <TwitterShareButton
              url="https://www.bibcitation.com"
              style={{ marginRight: 24, marginTop: 4 }}
              className={css.headerIconButton}
              aria-label="Visit our Twitter"
            >
              <TwitterSVG />
            </TwitterShareButton>
          </span>
          <AuthLinks />
        </nav>
      </div>
    </header>
  );
};

export default Header;
