import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  loadCitationList,
  newCitationList,
} from 'redux/modules/citation-list-module';
import { Store } from 'redux/store';
import CitationList from './citation-list';
import PlusSVG from 'static/svgs/icon/12/plus.svg';
import usePrevious from 'lib/hooks/usePrevious';
import { DEFAULT_CITATION_STYLE } from 'lib/citation-defaults';
import CitationListOptions from './citation-list-options';
import SharingModal from './sharing-modal';
import css from './citation-lists.module.css';

const CitationLists: React.FC = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const store = useStore();
  const [loadingListID, setLoadingListID] = useState<string | undefined>();
  const [showRename, setShowRename] = useState<boolean>(false);

  const lists = useSelector((state: Store) => state.citationList.lists);
  const currentListIndex = useSelector(
    (state: Store) => state.citationList.currentListIndex,
  );
  const prevListIndex = usePrevious(currentListIndex);
  const userStatus = useSelector((state: Store) => state.auth.status);

  useEffect(() => {
    const loadList = async (): Promise<void> => {
      if (loadingListID && currentListIndex === prevListIndex) {
        try {
          dispatch(loadCitationList({ listIDToLoad: loadingListID, store }));
        } catch (err) {
          // show error to user?
        }
      }
    };
    if (loadingListID && currentListIndex !== prevListIndex) {
      setLoadingListID(undefined);
    }

    loadList();
  }, [loadingListID, store, dispatch, currentListIndex, prevListIndex]);

  const handleCitationListClick = async (
    listIDToLoad: string,
  ): Promise<void> => {
    setLoadingListID(listIDToLoad);
    if (router.pathname !== '/') {
      router.push('/');
      try {
        dispatch(loadCitationList({ listIDToLoad: listIDToLoad, store }));
      } catch (err) {
        // show error to user?
      }
    }
  };

  const handleNewCitationListClick = (): void => {
    if (userStatus === 'not present') {
      router.push(
        `/signup?next=${encodeURIComponent(router.asPath)}&required=true`,
      );
    } else if (userStatus === 'present' && !loadingListID) {
      dispatch(
        newCitationList(
          lists[currentListIndex].styleKey || DEFAULT_CITATION_STYLE.name,
        ),
      );
    }
  };
  if (currentListIndex === -1) {
    return null;
  }
  return (
    <div className={css.citationLists}>
      <CitationList
        lists={lists}
        lastSelectedListIndex={currentListIndex}
        citationList={lists[currentListIndex]}
        selectCitationList={handleCitationListClick}
        createNewCitationList={handleNewCitationListClick}
        showRename={showRename}
        closeRename={() => setShowRename(false)}
      />
      <SharingModal />
      {userStatus === 'present' && (
        <CitationListOptions
          citationList={lists[currentListIndex]}
          openRename={() => setShowRename(true)}
        />
      )}

      <button
        className={css.newCitationList}
        onClick={handleNewCitationListClick}
      >
        <span className={css.newCitationListIcon}>
          <PlusSVG />
        </span>
        <span>New list</span>
      </button>
    </div>
  );
};

export default CitationLists;
