import React from 'react';
import Router from 'next/router';
import cx from 'classnames';
import css from './button.module.css';

export type ButtonSize = 'tiny' | 'small' | 'medium' | 'big';

type ButtonProps = React.PropsWithChildren<{
  type?:
    | 'brand'
    | 'brandDark'
    | 'default'
    | 'light'
    | 'lighter'
    | 'doc'
    | 'white'
    | 'danger'
    | 'warning'
    | 'link'
    | 'clean'
    | 'facebook'
    | 'twitter';
  size?: ButtonSize;
  fullWidth?: boolean;
  autoWidth?: boolean;
  width?: string;
  disabled?: boolean;
  href?: string;
  onClick?: (...args: any[]) => any;
  style?: React.CSSProperties;
  circle?: boolean;
  newTab?: boolean;
}>;

const Button: React.FC<ButtonProps> = ({
  type = 'brand',
  size,
  href,
  onClick,
  children,
  width,
  fullWidth,
  autoWidth,
  disabled,
  style,
  circle,
  newTab = false,
}: ButtonProps) => {
  const classNames = cx([
    type != css.clean && css.button,
    css[type],
    size && css[size],
    disabled && css.disabled,
    circle && css.circle,
    (width && css.width) || (fullWidth && css.fullWidth) || css.autoWidth,
    autoWidth && css.autoWidth,
  ]);
  let Element: React.ElementType = 'span';
  let extraProps = {};
  if (!disabled) {
    if (!onClick) {
      if (href) {
        Element = 'a';
        if (newTab) {
          extraProps = {
            href,
            target: '_blank',
          };
        } else {
          extraProps = {
            onClick: (ev: Event) => {
              ev.preventDefault();
              Router.push(href);
            },
            href,
          };
        }
      }
    } else {
      Element = 'button';
      extraProps = { onClick, type: 'submit', name: children };
    }
  }

  return (
    <Element
      {...extraProps}
      className={classNames}
      style={
        {
          '--props-width': width,
          ...style,
        } as React.CSSProperties
      }
    >
      {children}
    </Element>
  );
};
export default Button;
