import { RefObject, useEffect } from 'react';

const useClickOutsideDetector = <T extends HTMLElement>(
  ref: RefObject<T>,
  handleOutsideClick: () => void,
): void => {
  useEffect(() => {
    const onClickOutside = (event: MouseEvent): void => {
      if (!event || !event.target) return;
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleOutsideClick();
      }
    };

    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [ref, handleOutsideClick]);
};

export default useClickOutsideDetector;
